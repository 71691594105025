import React from 'react'
import PropTypes from 'prop-types'
import './DashboardSideNavbarItems.css'
import { Link } from 'react-router-dom'
import SMSIcon from '../../../Assets/Icons/sms-white.svg';
import ScheduleIcon from '../../../Assets/Icons/calendar-white.svg';
import ContactsIcon from '../../../Assets/Icons/contacts.svg';
import DashboardSideNavbarSingleItem from './DashboardSideNavbarSingleItem';

const DashboardSideNavbarItems = props => {
  return (
    <div className="dashboardSideNavbar__items">
      <ul>
        <DashboardSideNavbarSingleItem imgSrc={ContactsIcon} text="Contacts" link="/dashboard/contacts" />
        <DashboardSideNavbarSingleItem imgSrc={ScheduleIcon} text="Calendar" link="/dashboard/schedule" />
        <DashboardSideNavbarSingleItem imgSrc={SMSIcon} text="SMS" link="/dashboard/sms" />
      </ul>
    </div>
  )
}

DashboardSideNavbarItems.propTypes = {

}

export default DashboardSideNavbarItems
