import React from 'react'
//CSS
import './LocationColour.css';

const LocationColour = ({ colour }) => {
  return (
    <div className="locationColour" style={{ backgroundColor: colour }}>

    </div>
  )
}

export default LocationColour
