import React from 'react'
import PropTypes from 'prop-types'
import './DashboardBody.css';

const DashboardBody = ({ children }) => {
  return (
    <div className="dashboardBody">
      {children}
    </div>
  )
}

DashboardBody.propTypes = {

}

export default DashboardBody
