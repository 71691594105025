import React from 'react'
import PropTypes from 'prop-types'
import './DashboardTopNavbar.css';
import calendarIcon from '../../Assets/Icons/calendar.svg';
import Button from '../Button/Button';

const DashboardTopNavbar = (props) => {


  const dateOptions = {
    year: 'numeric', month: 'long', day: 'numeric'
  }
  const today = new Date().toLocaleString('en-gb', dateOptions);

  return (
    <div className="dashboardTopNavbar">
      <div className="dashboardTopNavbar_left">
        <img src={calendarIcon} alt="Calendar Icon" />
        <p>{today}</p>
      </div>
      <div className="dashboardTopNavbar_right">

      </div>
    </div>
  )
}

DashboardTopNavbar.propTypes = {

}

export default DashboardTopNavbar
