import React from 'react'

const DashboardCard = ({ title, body, type, colour }) => {
  return (
    <div className="card">
      <div className="card-title">
        <h3 style={{ color: colour }}>{title}</h3>
      </div>
      <div className="card-body">
        {type === 'rate' ? <h1 style={{ color: colour }}>{`${body}%`}</h1> : <h1 style={{ color: colour }}>{body}</h1>}
      </div>
    </div>
  )
}

export default DashboardCard
