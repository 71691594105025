import axios from 'axios';
import { setAlert } from '../alert/alert.action';
import { REGISTER_SUCCESS, REGISTER_FAIL, USER_LOADED, AUTH_ERROR, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, SET_LOADING, SET_FIRST_LOGIN_FALSE, USER_UPDATE_ERROR } from '../types';
import setAuthToken from '../../Utils/setAuthToken';

// Load user

export const loadUser = () => async dispatch => {
  if (localStorage.token) {
    setAuthToken(localStorage.token)
  }

  try {
    const res = await axios.get('/api/auth')

    dispatch({
      type: USER_LOADED,
      payload: res.data
    })

  } catch (error) {
    dispatch({
      type: AUTH_ERROR
    })
  }
}


// Register a User
export const register = ({ firstName, lastName, email, contact, password }) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },

  }

  const body = JSON.stringify({ firstName, lastName, email, contact, password })

  try {
    const res = await axios.post('/api/users', body, config)
    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data
    })
    dispatch(loadUser())
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
    }
    dispatch({
      type: REGISTER_FAIL
    })
  }

}

// Login a User
export const login = (email, password) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },

  }

  const body = JSON.stringify({ email, password })

  try {
    const res = await axios.post('/api/auth', body, config)

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data
    })

    dispatch(loadUser());

  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
    }
    dispatch({
      type: LOGIN_FAIL
    })
  }

}

// Logout a User and clear profile

export const logout = () => dispatch => {
  dispatch({
    type: LOGOUT
  })

  dispatch(setAlert('You have been logged out.', 'danger'));
}

// Set loading
export const setLoading = () => dispatch => {
  dispatch({
    type: SET_LOADING
  })
}

export const setFirstLoginFalse = () => async dispatch => {

  try {

    dispatch(setLoading());

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const res = await axios.put('/api/users/disableProf', config)



    dispatch({
      type: SET_FIRST_LOGIN_FALSE,
      payload: res.data
    })

    dispatch(loadUser());

  } catch (error) {
    dispatch({
      type: USER_UPDATE_ERROR
    })
  }


}