import React from 'react'
import Logo from '../../Assets/TxtBotLogo/Logo Variations/PNGs/TxtBotLogo_Main_Logo_Color.png';
import './NavbarLeft.css';

const NavbarLeft = () => {
  return (
    <div className="navbarLeft d-flex flex-col">
      <img className="logo" src={Logo} alt="txtbot-logo" />
    </div>
  )
}

export default NavbarLeft
