import { REGISTER_SUCCESS, REGISTER_FAIL, USER_LOADED, AUTH_ERROR, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, SET_LOADING, SET_FIRST_LOGIN_FALSE, USER_UPDATE_ERROR } from '../types';

const initialState = {

  token: localStorage.getItem('token'),
  isAuthenticated: null,
  iasAdmin: false,
  loading: true,
  user: {

  }

}

const userReducer = (state = initialState, action) => {

  const { type, payload } = action;

  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true
      }
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload
      }
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      localStorage.setItem('token', payload.token)
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false
      }
    case SET_FIRST_LOGIN_FALSE:
      return {
        ...state,
        user: payload
      }
    case REGISTER_FAIL:
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem('token')
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false
      }
    case USER_UPDATE_ERROR:
    default:
      return state;

  }

}

export default userReducer;