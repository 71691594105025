import { combineReducers } from 'redux';
import user from './user/user.reducer';
import alert from './alert/alert.reducer';
import profile from './profile/profile.reducer';
import messages from './messages/messages.reducer';
import contacts from './contacts/contacts.reducer';
import appointments from './appointments/appointments.reducer';

export default combineReducers({
  user,
  alert,
  profile,
  messages,
  contacts,
  appointments
})