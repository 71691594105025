import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
//Redux
import { connect } from 'react-redux';
import { sendSMS } from '../../Redux/messages/messages.actions';
//CSS
import './SMSSend.css'
//Components
import SendIcon from '../../Assets/Icons/send.svg';

const SMSSend = ({ sendSMS, contact, profile }) => {

  console.log(profile.settings.sms.from);

  const [msgObj, setMsgObj] = useState({
    from: 'Txtbot',
    to: contact,
    body: ''
  });

  useEffect(() => {
    setMsgObj((prevState) => {
      return {
        ...prevState,
        to: contact,
        from: profile.settings.sms.from
      }
    })
  }, [contact])

  console.log(msgObj);

  return (
    <div className="smsSend">
      <div className="smsSendInput">
        <input type="text" placeholder="Send an instant message" name="body" value={msgObj.body} onChange={(e) => {
          setMsgObj((prevState) => {
            return {
              ...prevState,
              body: e.target.value
            }
          })
        }} />
        <button onClick={() => sendSMS(msgObj)}>
          <img src={SendIcon} alt="" />
        </button>
      </div>

    </div>
  )
}

SMSSend.propTypes = {
  sendSMS: PropTypes.func.isRequired,
  contact: PropTypes.string.isRequired
}

export default connect(null, { sendSMS })(SMSSend)
