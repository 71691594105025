import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './DashboardPopup.css';
import DashboardPopupInput from './DashboardPopupInput';
import { connect } from 'react-redux';
import { createProfile } from '../../../Redux/profile/profile.actions';
import { setFirstLoginFalse } from '../../../Redux/user/user.actions';

const DashboardPopup = ({ setLater, createProfile, user, setFirstLoginFalse }) => {


  const [section, setSection] = useState(1);

  const [profileInfo, setProfileInfo] = useState({

    businessInfo: {

    },
    settings: {
      enabledFeatures: [],
      sms: {
        from: ''
      }
    }

  });

  console.log(profileInfo)

  switch (section) {
    case 1:
      return (
        <div className="h-100 w-100 dashboardPopup">
          <div className="dashboardPopupTitle">
            <h4>Getting Started</h4>
          </div>
          <div className="dashboardPopupBody">
            <h1>Welcome to Txtbot!</h1>
            <p>To help us make sure that you have everything you need, let's first set up your business profile. Here you can personalise the available features to suit your needs, as well as provide a custom brand to make the software really feel like your own!</p>
            <p>If you're ready to get started, hit <strong>Next</strong>.</p>
          </div>
          <div className="dashboardPopupFooter">
            <button className="btn btn-alternate" onClick={() => setLater(true)}>Setup Later</button>
            <button className="btn" onClick={() => setSection(prevState => prevState + 1)}>Next</button>
          </div>
        </div>
      )
    case 2:
      return ((
        <div className="h-100 w-100 dashboardPopup">
          <div className="dashboardPopupTitle">
            <h4>Getting Started</h4>
          </div>
          <div className="dashboardPopupBody">
            <h1>Tell us a little bit about your business</h1>
            <input type="text" placeholder="What is the name of your business?" name="name" value={profileInfo.businessInfo.name} onChange={(e) => setProfileInfo((prevState) => {
              return {
                ...prevState,
                businessInfo: {
                  ...prevState.businessInfo,
                  name: e.target.value
                }
              }
            })} />
            <input type="text" placeholder="Choose a shortcode to appear as your SMS sender details (max 10 characters)" name="from" value={profileInfo.settings.sms.from} onChange={(e) => setProfileInfo((prevState) => {
              if (e.target.value.length <= 10) {
                return {
                  ...prevState,
                  settings: {
                    ...prevState.settings,
                    sms: {
                      from: e.target.value
                    }
                  }
                }
              } else {
                return prevState;
              }
            })} />
            <input type="text" placeholder="What is your primary Business address?" name="address" value={profileInfo.businessInfo.address} onChange={(e) => {
              setProfileInfo((prevState) => {
                return {
                  ...prevState,
                  businessInfo: {
                    ...prevState.businessInfo,
                    address: e.target.value
                  }
                }
              })
            }} />
          </div>
          <div className="dashboardPopupFooter">
            <button className="btn btn-alternate" onClick={() => setSection(prevState => prevState - 1)}>Go Back</button>
            <button className="btn" onClick={() => setSection(prevState => prevState + 1)}>Next</button>
          </div>
        </div>
      ))
    case 3:
      return ((
        <div className="h-100 w-100 dashboardPopup">
          <div className="dashboardPopupTitle">
            <h4>Getting Started</h4>
          </div>
          <div className="dashboardPopupBody">
            <h1>What features are you going to need?</h1>
            <form className="dashboardPopupBodyFeatures" action="">
              <DashboardPopupInput value="Single Appointments" profileInfo={profileInfo} handleClick={setProfileInfo} />
              <DashboardPopupInput value="Group Bookings" profileInfo={profileInfo} handleClick={setProfileInfo} />
              <DashboardPopupInput value="Marketing" profileInfo={profileInfo} handleClick={setProfileInfo} />
              <DashboardPopupInput value="General Reminders" profileInfo={profileInfo} handleClick={setProfileInfo} />
              <DashboardPopupInput value="Payment Reminders" profileInfo={profileInfo} handleClick={setProfileInfo} />
              <DashboardPopupInput value="Multiple Locations" profileInfo={profileInfo} handleClick={setProfileInfo} />
            </form>
          </div>
          <div className="dashboardPopupFooter">
            <button className="btn btn-alternate" onClick={() => {
              setSection(prevState => prevState - 1)
              setProfileInfo(prevState => {
                return {
                  ...prevState,
                  settings: {
                    ...prevState.settings,
                    enabledFeatures: []
                  }
                }
              })
            }}>Go Back</button>
            <button className="btn" onClick={async (e) => { e.preventDefault(); await createProfile(profileInfo); await setFirstLoginFalse() }}>Save Profile</button>
          </div>
        </div >
      ))
    default:
      return (
        <button className="btn btn-alternate" onClick={() => setSection(prevState => prevState - 1)}>Go Back</button>
      )
  }
}

DashboardPopup.propTypes = {
  setLater: PropTypes.func.isRequired,
  createProfile: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  setFirstLoginFalse: PropTypes.func.isRequired,

}

const mapStateToProps = state => ({
  user: state.user.user
})

export default connect(mapStateToProps, { createProfile, setFirstLoginFalse })(DashboardPopup)
