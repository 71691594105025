import { GET_APPOINTMENTS, ADD_APPOINTMENT, APPOINTMENTS_ERROR, DELETE_APPOINTMENT } from '../types';

const initialState = {
  appointments: [],
  loading: true
}

const appointmentsReducer = (state = initialState, action) => {

  const { type, payload } = action;

  switch (type) {
    case GET_APPOINTMENTS:
      return {
        appointments: payload,
        loading: false
      }
    case ADD_APPOINTMENT:
      return {
        appointments: [...state.appointments, payload],
        loading: false
      }
    case DELETE_APPOINTMENT:
      return {
        appointments: [state.appointments.filter((x) => { if(x !== payload) return payload})],
        loading: false
      }
    case APPOINTMENTS_ERROR:
    default:
      return state;
  }

}

export default appointmentsReducer;