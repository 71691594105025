import React, { useState } from 'react'
import PropTypes from 'prop-types'
//CSS
import './ProfileCardItem.css';

const ProfileCardItem = ({ text, edit, dataObj, setDataObj, objectPath, nestedObjectPath, name, maxChar }) => {

  console.log(dataObj);

  console.log(typeof maxChar)

  if (nestedObjectPath) {
    console.log('nested');
    return (
      <div className="profileCardItem">
        <h4>{`${name.charAt(0).toUpperCase()}${name.slice(1)}`}</h4>
        {!edit ? <p>{text}</p> : <input value={dataObj[objectPath][nestedObjectPath][name]} name={name} onChange={e => {
          if (e.target.value.length <= maxChar) {
            setDataObj(prevState => {
              return {
                ...prevState,
                [objectPath]: {
                  ...prevState[objectPath],
                  [nestedObjectPath]: {
                    [name]: e.target.value
                  }
                }
              }
            })
          } else {
            setDataObj(prevState => {
              return prevState
            })
          }
        }} />}
      </div>
    )
  } else {

    return (
      <div className="profileCardItem">
        <h4>{`${name.charAt(0).toUpperCase()}${name.slice(1)}`}</h4>
        {!edit ? <p>{text}</p> : <input value={dataObj[objectPath][name]} name={name} onChange={e => {
          if (e.target.value.length <= maxChar) {
            setDataObj(prevState => {
              return {
                ...prevState,
                [objectPath]: {
                  ...prevState[objectPath],
                  [name]: e.target.value
                }
              }
            })
          } else {
            setDataObj(prevState => {
              return prevState
            })
          }
        }} />}
      </div>
    )
  }
}

ProfileCardItem.propTypes = {

}

export default ProfileCardItem
