import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import './Dashboard.css';
//Redux
import { getProfile } from '../../Redux/profile/profile.actions';
import { getAppointments } from '../../Redux/appointments/appointments.actions';
import { getMessages } from '../../Redux/messages/messages.actions';
import { getContacts } from '../../Redux/contacts/contacts.actions';
//Components
import DashboardSideNavbar from '../../Components/Dashboard/DashboardSideNavbar/DashboardSideNavbar';
import DashboardMain from '../../Components/Dashboard/DashboardMain';
import DashboardTopNavbar from '../../Components/Dashboard/DashboardTopNavbar';
import DashboardInfoPanel from '../../Components/Dashboard/DashboardInfoPanel/DashboardInfoPanel';
import DashboardBody from '../../Components/Dashboard/DashboardBody';
import DashboardCard from '../../Components/Dashboard/DashboardCard';
import DashboardMainHeader from '../../Components/Dashboard/DashboardMainHeader';
import DashboardPopup from '../../Components/Dashboard/DashboardPopup/DashboardPopup';
import Alert from '../../Components/Alert/Alert'
import Popup from '../../Components/Popup/Popup';
import SmsRoute from './SMS/Sms';
import Contacts from './Contacts/Contacts';
import Schedule from './Schedule/Schedule';
import Profile from './Profile/Profile';



const Dashboard = ({ user, getProfile, profile: { profile }, messages, appointments, getAppointments, getContacts }) => {

  useEffect(() => {
    getProfile();
    getAppointments();
    getContacts();
  }, [])

  const [later, setLater] = useState(false);

  return (

    <div className="dashboard w-100 h-100 d-flex">
      <Popup show={user.firstLogin && !later && true}>
        <DashboardPopup setLater={setLater} />
      </Popup>
      <DashboardSideNavbar profile={profile} />
      <DashboardMain>
        <DashboardTopNavbar />
        <DashboardBody>
          <Switch>
            <Route path="/dashboard" exact>
              {user.firstName ? <DashboardMainHeader name={user.firstName} /> : 'Loading...'}
              <DashboardCard title="Texts Remaining" body={profile ? profile.smsBalance : '0'} colour={profile && profile.settings.colourScheme.secondary} />
              <DashboardCard title="Texts Scheduled" body="--" colour={profile && profile.settings.colourScheme.secondary} />
              <DashboardCard title="Avg. Open Rate" type="rate" body="--" colour={profile && profile.settings.colourScheme.secondary} />
              <DashboardCard title="Direct Replies" body="--" colour={profile && profile.settings.colourScheme.secondary} />
              <DashboardCard title="Unread Messages" body="--" colour={profile && profile.settings.colourScheme.secondary} />
              <DashboardCard title="Total Texts Sent" body={profile ? profile.sent : '0'} colour={profile && profile.settings.colourScheme.secondary} />
            </Route>
            <Route path="/dashboard/contacts" exact>
              <Contacts profile={profile} />
            </Route>
            <Route path="/dashboard/schedule" exact>
              <Schedule appointments={appointments} profile={profile} />
            </Route>
            <Route path="/dashboard/sms" exact>
              <SmsRoute messages={messages} />
            </Route>
            <Route path="/dashboard/profile" exact>
              <Profile user={user} />
            </Route>
          </Switch>
        </DashboardBody>
      </DashboardMain>
      <DashboardInfoPanel />
      <Alert />
    </div>

  )
}

Dashboard.propTypes = {
  user: PropTypes.object.isRequired,
  getProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  getAppointments: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  user: state.user.user,
  profile: state.profile,
  messages: state.messages,
  appointments: state.appointments
})

export default connect(mapStateToProps, { getProfile, getMessages, getAppointments, getContacts })(Dashboard)
