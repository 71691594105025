import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './sms.css';
//Redux
import { connect } from 'react-redux';
import { getMessages } from '../../../Redux/messages/messages.actions';
import { getContacts } from '../../../Redux/contacts/contacts.actions';
//Components
import SMSChatContact from '../../../Components/SMS/SMSChatContact';
import SMSChatMessage from '../../../Components/SMS/SMSChatMessage';
import Loading from '../../../Components/Loading/Loading'
import SMSSend from '../../../Components/SMS/SMSSend';


const Sms = ({ messages: { messages, loading }, getMessages, user, contacts, getContacts, profile: { profile } }) => {

  useEffect(() => {
    getContacts(user._id);
    getMessages(user._id);
  }, [])


  const [show, toggleShow] = useState(false);

  const [activeContact, setActiveContact] = useState('');

  let activeMsg = messages.filter((value) => {
    return value.to === activeContact;
  })

  if (contacts.contacts.length > 0) {

    return (
      <div className="smsRoute h-100 w-100">
        <div className="smsRouteLeft h-100 w-100">
          <ul>
            {contacts.contacts.map(contact => {
              return <SMSChatContact profile={profile} key={contact._id} icon={`${contact && contact.firstName.slice(0, 1)}${contact.lastName.slice(0, 1)}`} contact={contact} handleClick={() => setActiveContact(`44${contact.contact}`)} activeContact={activeContact} />
            })}
          </ul>
        </div>
        <div className="smsRouteRight h-100 w-100">
          <div className="smsChatWindow h-100 w-100">
            {activeMsg.length > 0 ? activeMsg.map((msg) => {
              return <SMSChatMessage key={msg._id} currentUser msg={msg} profile={profile} />
            }) : null}
          </div>
          <div className="smsSendBox">
            {activeContact ? <SMSSend contact={activeContact} profile={profile} /> : null}
          </div>
        </div>
      </div>
    )
  } else if (!loading && contacts.contacts.length < 1) {
    return (
      <div className="smsRoute h-100 w-100">
        <div className="smsRouteLeft h-100 w-100">
          <ul>
            <SMSChatContact contactNumber="SMS contacts will appear here once added." icon={false} />
          </ul>
        </div>
        <div className="smsRouteRight h-100 w-100">

          <div className="smsChatWindow w-100">

          </div>
        </div>
      </div>
    )
  }
  else {
    return <Loading />
  }
}

Sms.propTypes = {
  messages: PropTypes.object.isRequired,
  getMessages: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  contacts: PropTypes.object.isRequired,
  getContacts: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  messages: state.messages,
  user: state.user.user,
  contacts: state.contacts,
  profile: state.profile
})

export default connect(mapStateToProps, { getMessages, getContacts })(Sms)