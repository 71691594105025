import React from 'react'
import './SMSChatMessage.css';

const SMSChatMessage = ({ currentUser, msg: { body, status, timestamp }, profile }) => {

  let timeStamp = new Date(timestamp);
  let date = timeStamp.toLocaleDateString();
  let time = timeStamp.toLocaleTimeString();

  return (
    <div className="smsChatMessage" style={currentUser && { alignSelf: 'flex-end', backgroundColor: profile.settings.colourScheme.primary, color: '#FFF' }}>
      <div className="smsChatMessageBody">
        {body}
      </div>
      <div className="smsChatMessageFooter">
        <p>{status}</p>
        <p>{`${date} ${time}`}</p>
      </div>
    </div>
  )
}

export default SMSChatMessage
