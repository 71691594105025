import React from 'react';
import './Navbar.css';
import NavbarLeft from './NavbarLeft';
import NavbarRight from './NavbarRight';


const Navbar = () => {
  return (
    <nav className="w-100 d-flex flex-row">
      <NavbarLeft />
      <NavbarRight />
    </nav>
  )
}

export default Navbar