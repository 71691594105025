import { GET_MESSAGES, SEND_MESSAGE, MESSAGES_ERROR, SET_MESSAGES_LOADING } from '../types';

const initialState = {
  messages: [],
  loading: true
}

const messagesReducer = (state = initialState, action) => {

  const { type, payload } = action;

  switch (type) {
    case GET_MESSAGES:
      return {
        messages: payload,
        loading: false
      }
    case SEND_MESSAGE:
      return {
        messages: [...state.messages, payload],
        loading: false
      }
    case SET_MESSAGES_LOADING:
      return {
        ...state,
        loading: true
      }
    case MESSAGES_ERROR:
    default:
      return state;
  }

}

export default messagesReducer;