import React from 'react'
//CSS
import './ProfilePhoto.css';
//Components
import AddButton from '../addButton/addButton';
import Logo from '../../Assets/Logos/ppt-logo.svg';

const ProfilePhoto = ({ img, profile }) => {
  console.log(Logo)
  return (
    <div className="profilePhotoContainer">
      <div className="profilePhoto" style={{ backgroundImage: `url(${img})` }}>
        <AddButton color={profile.settings.colourScheme.primary} fixed />
      </div>

    </div>

  )
}

export default ProfilePhoto
