import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './Contacts.css';

//Redux
import { connect } from 'react-redux';
import { getContacts } from '../../../Redux/contacts/contacts.actions';

//Components
import DashboardContactCard from '../../../Components/Dashboard/DashboardContacts/DashboardContactCard'
import AddButton from '../../../Components/addButton/addButton';
import Popup from '../../../Components/Popup/Popup';
import Loading from '../../../Components/Loading/Loading';
import AddContact from '../../../Components/Dashboard/DashboardContacts/DashboardContactAdd';

const Contacts = ({ getContacts, contacts: { contacts, loading }, user: { _id }, profile }) => {

  useEffect(() => {
    getContacts(_id);
  }, [])

  const [showPopup, toggleShowPopup] = useState(false);

  if (!loading && contacts.length > 0) {

    return (
      <div className="contacts">
        <Popup show={showPopup} >
          <AddContact handleClick={() => { toggleShowPopup(!showPopup) }} />
        </Popup>
        <div className="contactsActionBar">
          <AddButton color={profile.settings.colourScheme.primary} handleClick={() => toggleShowPopup(!showPopup)}>

          </AddButton>
        </div>
        <div className="contactsCards">
          {contacts.map(contact => {
            return (
              <DashboardContactCard key={contact._id} contact={contact} profile={profile} />
            )
          })}
        </div>
      </div>
    )

  } else if (!loading && contacts.length < 1) {

    return (
      <div className="contacts">
        <Popup show={showPopup} >
          <AddContact handleClick={() => { toggleShowPopup(!showPopup) }} />
        </Popup>
        <div className="contactsActionBar">
          <AddButton handleClick={() => toggleShowPopup(!showPopup)}>

          </AddButton>
        </div>
        <div className="contactsCards">
          <p>No contacts added yet. Click '<strong>+</strong>' to add your first contact.</p>
        </div>
      </div>
    )

  } else {
    return <Loading />
  }
}

Contacts.propTypes = {
  getContacts: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  contacts: state.contacts,
  user: state.user.user
})

export default connect(mapStateToProps, { getContacts })(Contacts)
