export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const SET_LOADING = 'SET_LOADING';
export const GET_PROFILE = 'GET_PROFILE';
export const CREATE_PROFILE = 'CREATE_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const SET_FIRST_LOGIN_FALSE = 'SET_FIRST_LOGIN_FALSE';
export const USER_UPDATE_ERROR = 'USER_UPDATE_ERROR';
export const GET_MESSAGES = 'GET_MESSAGES';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const MESSAGES_ERROR = 'MESSAGES_ERROR';
export const SET_MESSAGES_LOADING = 'SET_MESSAGES_LOADING';
export const GET_CONTACTS = 'GET_CONTACTS';
export const CREATE_CONTACT = 'CREATE_CONTACT';
export const CONTACTS_ERROR = 'CONTACTS_ERROR';
export const GET_APPOINTMENTS = 'GET_APPOINTMENTS';
export const ADD_APPOINTMENT = 'ADD_APPOINTMENT';
export const DELETE_APPOINTMENT = 'DELETE_APPOINTMENT';
export const APPOINTMENTS_ERROR = 'APPOINTMENTS_ERROR';


