import React, { useState } from 'react'
import PropTypes from 'prop-types'

//CSS
import './DashboardContactAdd.css';

//Redux
import { connect } from 'react-redux';
import { createContact } from '../../../Redux/contacts/contacts.actions';

//Components

const DashboardContactAdd = ({ handleClick, createContact }) => {

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    contact: ''
  });

  return (
    <div className="dashboardContactAdd">
      <div className="dashboardContactAddTitle">
        <h4>Add New Contact</h4>
      </div>
      <div className="dashboardContactAddBody">
        <h2>Please enter the details of the contact you'd like to add below.</h2>
        <form action="">
          <input type="text" placeholder="Enter Contact's First Name" name="firstName" value={formData.firstName} onChange={(e) => {
            setFormData(prevState => {
              return {
                ...prevState,
                firstName: e.target.value
              }
            })
          }} />
          <input type="text" placeholder="Enter Contact's Last Name" name="lastName" value={formData.lastName} onChange={(e) => {
            setFormData(prevState => {
              return {
                ...prevState,
                lastName: e.target.value
              }
            })
          }} />
          <div className="splitInput">
            <label htmlFor="contact">+44</label>
            <input type="number" min="1" placeholder="Enter Contact's Mobile Number" name="contact" value={formData.contact} onChange={(e) => {
              setFormData(prevState => {
                if (e.target.value.charAt(0) !== '0') {
                  return {
                    ...prevState,
                    contact: e.target.value
                  }
                } else {
                  return {
                    ...prevState
                  }
                }
              })
            }} />
          </div>
          <div className="dashboardContactAddButtons">
            <button className="btn" onClick={async (e) => { e.preventDefault(); await createContact(formData); handleClick(); }}>Add Contact</button>
            <button className="btn btn-alternate" onClick={(e) => { e.preventDefault(); handleClick() }}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  )
}

DashboardContactAdd.propTypes = {
  createContact: PropTypes.func.isRequired
}

export default connect(null, { createContact })(DashboardContactAdd)
