import React, { useState } from 'react'
import './DashboardPopupInput.css';

const DashboardPopupInput = ({ value, profileInfo, handleClick }) => {
  const [active, setActive] = useState(false);

  return (
    <input className={`dashboardPopupBodySingleFeature ${active ? 'activeFeature' : ''}`} value={value} onClick={async (e) => {

      handleClick(prevState => {

        if (!active) {

          return {
            ...prevState,
            settings: {
              ...prevState.settings,
              enabledFeatures: [...profileInfo.settings.enabledFeatures, e.target.value]
            }
          }

        }
        else {
          return {
            ...prevState,
            settings: {
              ...prevState.settings,
              enabledFeatures: profileInfo.settings.enabledFeatures.filter((feature) => feature !== e.target.value)
            }
          }
        };
      }
      );
      setActive(!active);
    }
    } readOnly />
  )
}

export default DashboardPopupInput
