import React from 'react'
import './Footer.css';
import fbIcon from '../../Assets/Icons/facebook.svg';
import igIcon from '../../Assets/Icons/instagram.svg';

const Footer = () => {
  return (
    <div className="footer">
      <p>Find us on Social Media!</p>
      <div className="socials">
        <a href="https://facebook.com/txtbot-101587029060936">
          <div className="social-icon" >
            <img src={fbIcon} alt="facebook-logo" />
          </div>
        </a>
        <a href="https://instagram.com/txt_bot">
          <div className="social-icon">
            <img src={igIcon} alt="instagram-logo" />
          </div>
        </a>
      </div>
    </div >
  )
}

export default Footer
