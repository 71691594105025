import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
//CSS
import './Schedule.css';
//Redux
import { connect } from 'react-redux';
import { getAppointments, deleteAppointment } from '../../../Redux/appointments/appointments.actions';
//Components
import AddButton from '../../../Components/addButton/addButton';
import Popup from '../../../Components/Popup/Popup';
import AddAppointment from '../../../Components/Dashboard/DashboardAppointments/DashboardAppointmentAdd';
import LocationColour from '../../../Components/Dashboard/DashboardAppointments/LocationColour';
//Assets


const Schedule = ({ appointments: { appointments }, getAppointments, deleteAppointment, profile: { profile } }) => {

  useEffect(() => {
    getAppointments();
  }, [])

  const options = {
    year: 'numeric', month: 'long', day: 'numeric'
  };

  appointments.sort((a, b) => {
    return (new Date(a.date).getTime()) - (new Date(b.date).getTime())
  })

  const [showPopup, setShowPopup] = useState(false);

  const [filterDate, setFilterDate] = useState(new Date());

  appointments.map(appointment => {
    console.log(new Date(appointment.date))
  })

  let filteredAppointments = appointments.filter((appointment) => {
    return new Date(appointment.date).toLocaleDateString() == new Date(filterDate).toLocaleDateString();
  })

  console.log(filteredAppointments);

  if (appointments.length > 0) {
    return (
      <div className="schedule">
        <div className="appointmentsActionBar">
          <div className="searchDate">
            <label htmlFor="filterDate">Search Date:</label>
            <input type="date" name="filterDate" value={filterDate} onChange={(e) => {
              setFilterDate(e.target.value);
            }} />
          </div>
          <AddButton color={profile && profile.settings.colourScheme.primary} handleClick={() => setShowPopup(!showPopup)}>
            <Popup show={showPopup}>
              <AddAppointment closePopup={() => setShowPopup(!showPopup)} />
            </Popup>
          </AddButton>
        </div>
        <div className="scheduleBody">
          {filteredAppointments.length > 0 ? filteredAppointments.map(appointment => {
            return (
              <div className="appointmentContainer">
                <div className="appointmentContainer__left">
                  <LocationColour colour={appointment.colour} />
                  <div className="appointmentContainer__left-time">
                    <p>{`${new Date(appointment.start).toLocaleTimeString().slice(0, 5)} - ${new Date(appointment.end).toLocaleTimeString().slice(0, 5)}`}</p>
                  </div>
                </div>
                <div className="appointmentContainer__center">
                  {appointment.attendees.map(attendee => {
                    return (
                      <h4>{`${attendee.name}`}</h4>
                    )
                  })}
                </div>
                <div className="appointmentContainer__right">
                  <p>{appointment.location}</p>
                </div>
                <div className="appointmentContainer__delete" onClick={() => { deleteAppointment(appointment._id) }}>

                  <strong><p>X</p></strong>

                </div>
              </div>
            )
          }) : <p>No appointments found for this date.</p>}
        </div>
      </div>
    )
  } else {
    return (
      <div className="schedule">
        <div className="appointmentsActionBar">
          <AddButton color={profile && profile.settings.colourScheme.primary} handleClick={() => setShowPopup(!showPopup)}>
            <Popup show={showPopup}>
              <AddAppointment closePopup={() => setShowPopup(!showPopup)} />
            </Popup>
          </AddButton>
        </div>
        <div className="scheduleBody">
          <p>No appointments have been created yet. Click '<strong>+</strong>' to schedule your first appointment.</p>
        </div>
      </div>
    )
  }
}

Schedule.propTypes = {
  getAppointments: PropTypes.func.isRequired,
  deleteAppointment: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  appointments: state.appointments,
  profile: state.profile
})

export default connect(mapStateToProps, { getAppointments, deleteAppointment })(Schedule)
