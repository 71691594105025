import React from 'react'
import './Features.css';
import Card from '../Card/Card';
import calendarIcon from '../../Assets/Icons/calendar.svg';
import smsIcon from '../../Assets/Icons/sms.svg';
import supportIcon from '../../Assets/Icons/support.svg';
import customIcon from '../../Assets/Icons/design.svg';
import featuresIcon from '../../Assets/Icons/features.svg';
import thumbsIcon from '../../Assets/Icons/thumbs-up.svg';

const Features = ({ children }) => {
  return (
    <div className="features">
      <Card text="Integrated Calendar" icon={calendarIcon} />
      <Card text="Automated SMS" icon={smsIcon} />
      <Card text="Dedicated Tech Support" icon={supportIcon} />
      <Card text="Custom Branding" icon={customIcon} />
      <Card text="Personalised Features" icon={featuresIcon} />
      <Card text="Simple & Friendly UI" icon={thumbsIcon} />
    </div>
  )
}

export default Features
