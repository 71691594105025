import React from 'react'
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../../Redux/user/user.actions';
import './DashboardSideNavbar.css';
import logo from '../../../Assets/TxtBotLogo/Logo Variations/PNGs/White-red-Icon.png';
import DashboardSideNavbarItems from './DashboardSideNavbarItems';
import DashboardSideNavbarSingleItem from './DashboardSideNavbarSingleItem';
import logoutIcon from '../../../Assets/Icons/sign-out.png';
import profileIcon from '../../../Assets/Icons/profile.svg';

//Components
import Loading from '../../Loading/Loading';

const DashboardNavbar = ({ logout, user: { isAuthenticated }, profile }) => {

  if (!isAuthenticated) {
    return <Redirect to="/" />
  }

  if (profile) {
    return (
      <div className="dashboardSideNavbar" style={{ backgroundColor: profile.settings.colourScheme.primary }}>
        <div className="dashboardSideNavbar__top">
          <Link to="/dashboard"><img src="White-red-Icon.png" /></Link>
        </div>
        <DashboardSideNavbarItems />
        <div className="dashboardSideNavbar__bottom">
          <DashboardSideNavbarSingleItem link="/dashboard/profile" imgSrc={profileIcon} text="Profile" />
          <DashboardSideNavbarSingleItem onClick={() => logout()} imgSrc={logoutIcon} link="/login" text="Logout" />
        </div>
      </div>
    )
  } else {
    return (
      <div className="dashboardSideNavbar">
        <Loading />
      </div>
    )
  }
}

DashboardNavbar.propTypes = {
  logout: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps, { logout })(DashboardNavbar)
