import React from 'react'
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './DashboardInfoPanel.css';
//Redux
import { connect } from 'react-redux';
//Components
import AppointmentItem from './DashboardInfoPanelItem';
import Loading from '../../Loading/Loading';

const DashbaordInfoPanel = ({ appointments: { appointments, loading } }) => {

  if (!loading && appointments.length > 0) {

    console.log(appointments)

    const filteredAppointments = appointments.filter((appointment) => {
      if ((new Date(appointment.start).getTime() - new Date().getTime()) > 0) {
        return appointment;
      }
    })

    filteredAppointments.sort((a, b) => {
      return (new Date(a.date).getTime()) - (new Date(b.date).getTime())
    })

    return (
      <div className="dashboardInfoPanel">
        <h3>Upcoming Appointments</h3>
        {filteredAppointments ? filteredAppointments.map(appointment => {
          return <AppointmentItem key={appointment._id} appointment={appointment} colour={appointment.colour} />
        }) : <p>No upcoming appointments</p>}
        {ReactDOM.createPortal(
          <div className='gcalBtn'>
            <button>Connect to Google Calendar</button>
          </div>,
          document.getElementById('gcal')
        )}
      </div>
    )

  } else if (!loading && appointments.length < 1) {
    return (
      <div className="dashboardInfoPanel">
        <h3>Upcoming Appointments</h3>
        <p>No upcoming appointments</p>
        {ReactDOM.createPortal(
          <div className='gcalBtn'>
            <button>Connect to Google Calendar</button>
          </div>,
          document.getElementById('gcal')
        )}
      </div>
    )
  } else {
    return (
      <div className="dashboardInfoPanel">
        <h3>Upcoming Appointments</h3>
        <Loading />
        {ReactDOM.createPortal(
          <div className='gcalBtn'>
            <button>Connect to Google Calendar</button>
          </div>,
          document.getElementById('gcal')
        )}
      </div>
    )
  }

}

DashbaordInfoPanel.propTypes = {
  appointments: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  appointments: state.appointments
})

export default connect(mapStateToProps)(DashbaordInfoPanel)
