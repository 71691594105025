import React, { useState } from 'react'
import PropTypes from 'prop-types'
//CSS
import './ProfileCard.css';
//Redux
import { connect } from 'react-redux';
import { createProfile } from '../../Redux/profile/profile.actions';

const ProfileCard = ({ children, title, icon, data, createProfile }) => {

  const [edit, setEdit] = useState(false);
  const [dataObj, setDataObj] = useState(data);

  let elements = React.Children.toArray(children);

  if (elements.length === 1) {
    elements = React.cloneElement(elements[0], { edit, dataObj, setDataObj })
  }
  else if (elements.length > 0) {

    elements = elements.map(element => {
      return React.cloneElement(element, { edit, dataObj, setDataObj })
    })
  }

  return (
    <div className="profileCard">
      <div className="profileCardTitle">
        <h3>{title}</h3>
        <img src={icon} alt="icon" />
      </div>
      <form action="">
        <div className="profileCardBody">
          {elements}
        </div>
      </form>
      <div className="profileCardFooter">
        {edit && <button className="btn btn-save" onClick={() => { createProfile(dataObj); setEdit(!edit); }}>Save</button>}
        <button className="btn btn-edit btn-alternate" onClick={() => setEdit(!edit)}>{edit ? 'Cancel' : 'Edit'}</button>
      </div>
    </div>
  )
}


ProfileCard.propTypes = {
  createProfile: PropTypes.func.isRequired
}

export default connect(null, { createProfile })(ProfileCard)
