import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { login, setLoading } from '../../Redux/user/user.actions';
import '../Auth.css';
import '../../Components/Form/Form.css';
import '../../Components/Form/FormField.css';
import Logo from '../../Assets/TxtBotLogo/Logo Variations/PNGs/TxtBotLogo_Main_Logo_Color.png';
import Loading from '../../Components/Loading/Loading';
import Alert from '../../Components/Alert/Alert'

const Login = ({ login, isAuthenticated, loading, setLoading }) => {

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const { email, password } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const onSubmit = (e) => {
    e.preventDefault();
    login(email, password)
  }

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />
  }

  return (
    <div className="auth h-100 w-100">
      <div className="authLeft h-100 w-50">
        <Link to="/"><img src={Logo} alt="Logo" /></Link>
        <div className="form">
          <form onSubmit={e => { onSubmit(e); setLoading(); }}>
            <h1>Welcome back!</h1>
            <p>Enter your login details below. Don’t have an account yet? <Link to="/register">Sign Up</Link></p>
            <input type="email" placeholder="Enter your Email Address" name="email" value={email} onChange={e => onChange(e)} required />
            <input type="password" placeholder="Enter your Password" name="password" value={password} onChange={e => onChange(e)} required />
            {!loading ? <input type="submit" className="btn" value="Login" /> : <Loading />}
          </form>
        </div>
      </div>
      <div className="authRight h-100 w-50">
        <Alert />
      </div>
    </div>
  )
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  isAuthenticated: state.user.isAuthenticated,
  loading: state.user.loading
})

export default connect(mapStateToProps, { login, setLoading })(Login);
