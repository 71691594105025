import axios from 'axios';
import { setAlert } from '../alert/alert.action';

import { GET_PROFILE, PROFILE_ERROR } from '../types';

//Get current profile

export const getProfile = () => async dispatch => {
  try {
    const res = await axios.get('/api/profile/me');

    dispatch({
      type: GET_PROFILE,
      payload: res.data
    })
  } catch (error) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    })
  }
}

// Create new profile or update existing

export const createProfile = (profileInfo) => async dispatch => {

  try {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const res = await axios.post('/api/profile', profileInfo, config);

    dispatch({
      type: GET_PROFILE,
      payload: res.data
    })

    dispatch(setAlert('Profile Successfully Updated!', 'success'))

  } catch (error) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    })
  }



}