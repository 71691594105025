import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
//CSS
import './LandingPage.css';
//Components
import Hero from '../Components/Hero/Hero';
import HeroLeft from '../Components/Hero/HeroLeft';
import HeroRight from '../Components/Hero/HeroRight';
import Navbar from '../Components/Navbar/Navbar'
import Main from '../Components/Main/Main';
import Features from '../Components/Features/Features';
import Footer from '../Components/Footer/Footer';
import video from '../Assets/Videos/txtbot-animated-video.mp4';

//Redux
import { connect } from 'react-redux';

const LandingPage = ({ user }) => {

  if (!user.isAuthenticated) {

    return (
      <Fragment>
        <div className="landingPage h-100 w-100 d-flex flex-col">
          <Navbar />
          <Hero>
            <HeroLeft />
            <HeroRight />
          </Hero>
        </div>
        <Main>
          <Features />
          <div className="videoContainer h-100 w-100" >
            <video className="video" width="1000" height="750" controls autoPlay={true} muted loop>
              <source src={video} type="video/mp4" />
            </video>
          </div>
        </Main>
        <Footer />
      </Fragment >
    )
  } else {
    return <Redirect to="/dashboard" />
  }
}

LandingPage.propTypes = {
  user: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps)(LandingPage)
