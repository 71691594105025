import axios from 'axios';
import { setAlert } from '../alert/alert.action';
import { GET_MESSAGES, SEND_MESSAGE, MESSAGES_ERROR, SET_MESSAGES_LOADING } from '../types';
import { getProfile } from '../profile/profile.actions';

export const setLoading = () => async dispatch => {
  try {
    dispatch({
      type: SET_MESSAGES_LOADING
    })
  } catch (error) {
    dispatch({
      type: MESSAGES_ERROR
    })
  }
}

export const getMessages = (id) => async dispatch => {

  try {

    const res = await axios.get(`/api/messages/${id}`)

    dispatch({
      type: GET_MESSAGES,
      payload: res.data
    })

  } catch (error) {
    dispatch({
      type: MESSAGES_ERROR
    })
  }

}

export const sendSMS = (smsObj) => async dispatch => {

  console.log(smsObj);
  console.log('sms conformation object reached')

  try {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const res = await axios.post('/api/messages', smsObj, config);

    dispatch({
      type: SEND_MESSAGE,
      payload: res.data
    })

    dispatch(setAlert('SMS successfully sent!', 'success'))

    dispatch(getProfile());

  } catch (error) {
    dispatch({
      type: MESSAGES_ERROR
    })

    dispatch(setAlert('SMS could not be sent. Please make sure you have enough credit and then try again.', 'danger'))
  }


}

