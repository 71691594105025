import React, { useState } from 'react'

const DashboardAppointmenAddOption = ({ contact, children, ...props }) => {




  return (
    <option {...props} onClick={() => console.log('Option')} data-contact={contact} >
      {children}
    </option>
  )
}

export default DashboardAppointmenAddOption
