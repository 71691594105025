import axios from 'axios';
import { GET_CONTACTS, CREATE_CONTACT, CONTACTS_ERROR } from '../types';
import { setAlert } from '../alert/alert.action';

export const getContacts = () => async dispatch => {

  try {
    const res = await axios.get(`/api/contacts/`);

    dispatch({
      type: GET_CONTACTS,
      payload: res.data
    })
  } catch (error) {
    dispatch({
      type: CONTACTS_ERROR
    })
  }

}

export const createContact = (formData) => async dispatch => {

  try {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const res = await axios.post('/api/contacts', formData, config);

    dispatch({
      type: CREATE_CONTACT,
      payload: res.data
    })

    dispatch(setAlert(`${formData.firstName} ${formData.lastName} has been successfully added to your contacts list.`, 'success'))

  } catch (error) {
    dispatch({
      type: CONTACTS_ERROR
    })
    dispatch(setAlert(`${formData.contact} has already been added to your contacts list.`, 'danger'))
  }

}

export const deleteContact = (formData) => async dispatch => {

}
