import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
//CSS
import './DashboardAppointmentAdd.css';
//Redux
import { connect } from 'react-redux';
import { addAppointment } from '../../../Redux/appointments/appointments.actions';
//Components
import Option from './DashboardAppointmentAddOption';
//import Times from './DashboardAppointmentTimes';
import Button from '../../Button/Button';

const DashboardAppointmentAdd = ({ closePopup, contacts: { contacts, loading }, user: { user }, profile: { profile }, addAppointment }) => {


  const [contactNum, setContactNum] = useState('');

  const [appointmentObj, setAppointmentObj] = useState({
    host: `${user.firstName} ${user.lastName}`,
    location: `${profile.businessInfo.address}`,
    date: new Date(),
    start: null,
    end: null,
    attendees: [],

  })

  const [msgObj, setMsgObj] = useState({
    from: profile.settings.sms.from,
    to: `44${contactNum}`,
    body: 'Default Text'
  })

  console.log(new Date(appointmentObj.start).toLocaleTimeString().slice(0,5))

  useEffect(() => {

    setMsgObj(prevState => {
      return {
        ...prevState,
        body: `Confirmation of appointment with ${appointmentObj.host} on ${new Date(appointmentObj.start).toLocaleDateString()} from ${new Date(appointmentObj.start).toLocaleTimeString().slice(0,5)} - ${new Date(appointmentObj.end).toLocaleTimeString().slice(0,5)} at ${appointmentObj.location}.`
      }
    })
    console.log(appointmentObj);
  }, [appointmentObj])


  return (

    <div className="dashboardAppointmentAdd">
      <div className="dashboardAppointmentAddTitle">
        <h4>Schedule an Appointment</h4>
      </div>
      <div className="dashboardAppointmentAddBody">
        <input type="text" placeholder="Enter Name of Host" value={appointmentObj.host} onChange={(e) => {
          setAppointmentObj(prevState => {

            return {
              ...prevState,
              host: e.target.value
            }

          })
        }} />
        <select name="attendees" id="attendees" onChange={(e) => {

          setMsgObj((prevState) => {
            return {
              ...prevState,
              to: `44${e.target.options[e.target.selectedIndex].dataset.contact}`
            }
          })

          setAppointmentObj(prevState => {
            return {
              ...prevState,
              attendees: [{
                name: e.target.value,
                contact: `44${e.target.options[e.target.selectedIndex].dataset.contact}`
              }]
            }
          })
        }}>
          <option value="">Please select a Contact</option>
          {contacts.length > 0 &&

            contacts.map((contact) => {
              return (
                <Option key={contact._id} value={`${contact.firstName} ${contact.lastName} `} contact={contact.contact}>
                  {`${contact.firstName} ${contact.lastName} `}
                </Option>
              );
            })

          }
        </select>
        {profile.settings.enabledFeatures.includes('Multiple Locations') ? (
          <select name="location" id="" onChange={(e) => {

            setAppointmentObj(prevState => {

              return {
                ...prevState,
                location: e.target.value,
                colour: e.target.options[e.target.selectedIndex].dataset.colour
              }

            })

          }}>
            {profile.businessInfo.locations.length > 0 ? profile.businessInfo.locations.map(location => {
              return <option key={location.address} data-colour={location.colour}>{location.address}</option>
            }) : <option>Please add locations to your profile</option>}
          </select>
        ) : <input type="text" placeholder="Enter appointment location" value={appointmentObj.location} onChange={(e) => {
          setAppointmentObj(prevState => {

            return {
              ...prevState,
              location: e.target.value
            }

          })
        }} />}

<div className="splitInput">
          <label htmlFor="startHour">Start time:</label>
          <input type="datetime-local" onChange={(e)=>{
              setAppointmentObj((prevState)=>{
                return {
                  ...prevState,
                  start: new Date(e.target.value)
                }
              })
          }}/>
        </div>

        <div className="splitInput">
          <label htmlFor="startHour">End time:</label>
          <input type="datetime-local" onChange={(e)=>{
              setAppointmentObj((prevState)=>{
                return {
                  ...prevState,
                  end: new Date(e.target.value)
                }
              })
          }}/>
        </div>



      </div>
      <div className="dashboardAppointmentAddFooter">
        <Button type="btn" onClick={async () => {

          addAppointment(appointmentObj, msgObj);
          closePopup()

        }} color={profile.settings.colourScheme.primary}>Schedule</Button>
        <Button type="btn btn-alternate" color={profile.settings.colourScheme.secondary} onClick={closePopup}>Cancel</Button>
      </div>
    </div>
  )
}

DashboardAppointmentAdd.propTypes = {
  user: PropTypes.object.isRequired,
  contacts: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  addAppointment: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  user: state.user,
  contacts: state.contacts,
  profile: state.profile
})

export default connect(mapStateToProps, { addAppointment })(DashboardAppointmentAdd)
