import React from 'react'
import './DashboardMainHeader.css';

const DashboardMainHeader = ({ name }) => {
  return (
    <div className="dashboardMainHeader">
      <h1>Welcome back, {name}!</h1>
    </div>
  )
}


export default DashboardMainHeader
