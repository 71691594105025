import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './DashboardSideNavbarSingleItem.css';
import { Link } from 'react-router-dom';
//Redux
import { connect } from 'react-redux';

const DashboardSideNavbarSingleItem = ({ imgSrc, text, link, onClick, profile: { profile } }) => {

  const [hover, setHover] = useState(false)

  return (

    <Link onClick={onClick} to={link}>
      <li className="DashboardSideNavbarSingleItem" style={hover && profile ? { color: profile.settings.colourScheme.secondary } : null} onMouseEnter={() => setHover(!hover)} onMouseLeave={() => setHover(!hover)} >
        <img src={imgSrc} />
        <span>{text}</span>
      </li>
    </Link>

  )
}

DashboardSideNavbarSingleItem.propTypes = {
  profile: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  profile: state.profile
})

export default connect(mapStateToProps)(DashboardSideNavbarSingleItem)
