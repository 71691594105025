import React from 'react'
import './Card.css';


const Card = ({ text, icon }) => {
  return (
    <div className="card">
      <div className="card-icon">
        {icon ? <img src={icon} alt="icon" /> : null}
      </div>
      <div className="card-info">
        <h1>{text}</h1>
      </div>
    </div>
  )
}

export default Card
