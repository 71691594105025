import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
//CSS
import './SMSChatContact.css'

const SMSChatContact = ({ contact, icon, handleClick, profile, activeContact }) => {

  const [pressed, setPressed] = useState(true);

  useEffect(() => {
    setPressed(false);
  }, [])

  if (contact) {
    return (

      <li className="smsChatContact" onClick={() => { handleClick(); setPressed(!pressed); }} style={pressed ? { transform: 'scale(0.95)', backgroundColor: '#EDEDF5' } : null}>
        <div className="smsChatContactLeft">
          {icon ? <p className="icon" style={profile && { backgroundColor: profile.settings.colourScheme.secondary }}>{icon}</p> : null}
          <h4>{`${contact.firstName} ${contact.lastName}`}</h4>
          <p>{`0${contact.contact}`}</p>
        </div>

      </li>

    )
  } else {
    return <p style={{ padding: '20px' }}>No contacts found. Please add a contact to begin sending SMS messages.</p>
  }


}

SMSChatContact.propTypes = {

}

export default SMSChatContact
