import React from 'react'
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import './Alert.css';

const Alert = ({ alerts }) => {

  if (alerts !== null && alerts.length > 0) {

    return ReactDOM.createPortal(
      <div className="alertsContainer">
        {alerts.map(
          alert => (
            <div key={alert.id} className={`alert alert-${alert.alertType}`}>
              {alert.msg}
            </div>
          )
        )}
      </div>,
      document.getElementById('alert')
    )
  } else {
    return null;
  }
}

alert.propTypes = {
  alerts: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  alerts: state.alert
})

export default connect(mapStateToProps)(Alert)
