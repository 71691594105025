import React from 'react'
import './DashboardMain.css';

const DashboardMain = ({ children }) => {
  return (
    <div className="dashboardMain">
      {children}
    </div>
  )
}


export default DashboardMain
