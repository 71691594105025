import axios from 'axios';
import { GET_APPOINTMENTS, ADD_APPOINTMENT, DELETE_APPOINTMENT, APPOINTMENTS_ERROR } from '../types';
import { setAlert } from '../alert/alert.action';
import { sendSMS } from '../messages/messages.actions'

export const getAppointments = () => async dispatch => {

  try {
    const res = await axios.get(`/api/appointments`);

    dispatch({
      type: GET_APPOINTMENTS,
      payload: res.data
    })
  } catch (error) {
    dispatch({
      type: APPOINTMENTS_ERROR
    })
  }

}

export const addAppointment = (appointmentInfo, smsObj) => async dispatch => {

  try {

    console.log('Scheduling appointment...')

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const res = await axios.post('/api/appointments', appointmentInfo, config);

    dispatch({
      type: ADD_APPOINTMENT,
      payload: res.data
    })

    dispatch(setAlert('Appointment Successfully Updated!', 'success'));

    dispatch(sendSMS(smsObj));

  } catch (error) {
    dispatch({
      type: APPOINTMENTS_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    })
  }

}

export const deleteAppointment = (id) => async dispatch => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const res = await axios.delete(`/api/appointments/${id}`, config);
    dispatch({
      type: DELETE_APPOINTMENT,
      payload: res.data
    })
    dispatch(setAlert('Appointment Successfully Deleted!', 'success'));
  } catch (error) {
    dispatch({
      type: APPOINTMENTS_ERROR,
      payload: { msg: 'Failed.' }
    })
    dispatch(setAlert('Appointment could not be deleted.', 'danger'));
  }

}