import React from 'react'

//CSS
import './DashboardInfoPanelItem.css';

//Components
import LocationColour from '../DashboardAppointments/LocationColour';

const DashboardInfoPanelItem = ({ appointment, colour }) => {
  return (
    <div className="dashboardInfoPanelItem">
      <LocationColour colour={colour} />
      {appointment.attendees.map(attendee => {
        return <p>{attendee.name}</p>
      })}
      <p id="infoPanelDate">{`${new Date(appointment.date).toLocaleDateString().slice(0, 5)} ${new Date(appointment.start).toLocaleTimeString().slice(0, 5)}`}</p>
    </div >
  )
}

export default DashboardInfoPanelItem
