import React from 'react'
import ReactDOM from 'react-dom';
import './Popup.css';

const Popup = ({ children, show, handleClick }) => {

  if (show) {
    return ReactDOM.createPortal(
      <div className="popup" onClick={handleClick}>
        <div className="popupContainer">
          {children}
        </div>
      </div>,
      document.getElementById('popup'));
  } else {
    return null;
  }
}

export default Popup
