import React from 'react'
import './Button.css';
//Redux

const Button = ({ children, color, type, onClick }) => {



  return (
    <button className={type} style={type === 'btn' || type === 'addBtn' ? { backgroundColor: color, color: '#FFF', border: `3px solid ${color}` } : { backgroundColor: 'transparent', color: color, border: `3px solid ${color}` }} onClick={onClick}>
      {children}
    </button>
  )
}

export default Button
