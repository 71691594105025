import React from 'react'
import './HeroRight.css';
import bgImage from '../../Assets/txtbot-hero.jpg';

const HeroRight = () => {
  return (
    <div className="heroRight w-60 h-100" style={{ backgroundImage: `url(${bgImage})` }}>

    </div>
  )
}

export default HeroRight
