import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import './addButton.css';
import plusIcon from '../../Assets/Icons/plus.svg';
//Components
import Button from '../Button/Button';

const addButton = ({ children, handleClick, fixed, color }) => {
  return (
    <Fragment>
      <Button type="addBtn" color={color} onClick={handleClick} style={fixed && { position: 'fixed' }}>
        <img id="plusIcon" src={plusIcon} />
      </Button>
      {children}
    </Fragment>
  )
}

addButton.propTypes = {

}

export default addButton
