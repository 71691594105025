import React from 'react'
import PropTypes from 'prop-types'
//CSS
import './Profile.css';
//Redux
import { connect } from 'react-redux';
//Components
import Loading from '../../../Components/Loading/Loading'
import ProfilePhoto from '../../../Components/Profile/ProfilePhoto';
import DefaultUser from '../../../Assets/default-user.png';
import ProfileCard from '../../../Components/Profile/ProfileCard';
import ProfileCardItem from '../../../Components/Profile/ProfileCardItem';
import InfoIcon from '../../../Assets/Icons/info.svg';
import SettingsIcon from '../../../Assets/Icons/settings.svg';

const Profile = ({ profile: { profile, loading }, user }) => {
  if (!loading && profile) {
    return (
      <div className="profile">
        <div className="profileLeft">
          <ProfilePhoto img={DefaultUser} profile={profile} />
          <h3>{`${user.firstName} ${user.lastName}`}</h3>
        </div>
        <div className="profileRight">
          <ProfileCard title="Business Information" icon={InfoIcon} data={profile}>
            <ProfileCardItem text={profile.businessInfo.name} objectPath="businessInfo" name="name" maxChar={100} />
            <ProfileCardItem text={profile.businessInfo.address} objectPath="businessInfo" name="address" maxChar={100} />
          </ProfileCard>
          <ProfileCard title="SMS Settings" icon={SettingsIcon} data={profile}>
            <ProfileCardItem text={profile.settings.sms.from} objectPath="settings" nestedObjectPath="sms" name="from" maxChar={10} />
          </ProfileCard>
        </div>
      </div>
    )
  } else {
    return <Loading />
  }
}

Profile.propTypes = {
  profile: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  profile: state.profile
})

export default connect(mapStateToProps)(Profile)
