import React from 'react'
import './DashboardContactCard.css';

// Icons
import SMSIcon from '../../../Assets/Icons/sms.svg';
import CalendarIcon from '../../../Assets/Icons/calendar.svg';
import DeleteIcon from '../../../Assets/Icons/recycle-bin.svg';

const DashboardContactCard = ({ contact, profile }) => {


  return (
    <div className="dashboardContactCard">
      <div className="dashboardContactCardHeader">
        <div className="dashboardContactCardHeader__icon" style={{ backgroundColor: profile.settings.colourScheme.secondary }}>
          <p>{`${contact.firstName.slice(0, 1)}${contact.lastName.slice(0, 1)}`}</p>
        </div>
      </div>
      <div className="dashboardContactCardBody">
        <h3>{`${contact.firstName} ${contact.lastName}`}</h3>
        <p>{contact.contact}</p>
      </div>
      <div className="dashboardContactCardFooter">
        <img src={SMSIcon} alt="" />
        <img src={CalendarIcon} alt="" />
        <img src={DeleteIcon} alt="" />
      </div>
    </div>
  )
}

export default DashboardContactCard
