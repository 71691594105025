import React from 'react'
import './Hero.css';

const Hero = ({ children }) => {
  return (
    <div className="hero d-flex flex-row h-100 v-100">
      {children}
    </div>
  )
}

export default Hero
