import React from 'react'
import './HeroLeft.css';
import Button from '../Button/Button';
import HeroLeftTitle from './HeroLeftTitle';

const HeroLeft = () => {
  return (
    <div className="heroLeft w-40 h-100 d-flex flex-column">
      <HeroLeftTitle />
      <div className="cta-buttons">
        <Button color="#5050BF" type="btn">
          SEE PLANS & PRICING
        </Button>
        <Button color="#EF626C" type="btn btn-alternate">
          TALK TO AN EXPERT
        </Button>
      </div>
    </div>
  )
}

export default HeroLeft
