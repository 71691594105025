import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setAlert } from '../../Redux/alert/alert.action';
import { register, setLoading } from '../../Redux/user/user.actions';
import Alert from '../../Components/Alert/Alert';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom'
import '../Auth.css';
import '../../Components/Form/Form.css';
import '../../Components/Form/FormField.css';
import Loading from '../../Components/Loading/Loading';

const Register = ({ setAlert, register, loading, setLoading, isAuthenticated }) => {

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    contact: '',
    password: '',
    password2: ''
  });

  const { firstName, lastName, email, contact, password, password2 } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (password !== password2) {
      setAlert('Passwords do not match', 'danger')
    } else {
      register({ firstName, lastName, email, contact, password });
      setLoading();
    }
  }

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />
  }

  return (
    <div className="auth h-100 w-100">
      <div className="authLeft h-100 w-50">
        <div className="form">
          <form onSubmit={e => { onSubmit(e); }}>
            <h1>Improve your communications</h1>
            <p>Enter your details to create an account. Already have an account? <Link to="/login">Login</Link></p>
            <input type="text" placeholder="Enter your First Name" name="firstName" value={firstName} onChange={e => onChange(e)} required />
            <input type="text" placeholder="Enter your Last Name" name="lastName" value={lastName} onChange={e => onChange(e)} required />
            <input type="email" placeholder="Enter your Email Address" name="email" value={email} onChange={e => onChange(e)} required />
            <input type="number" placeholder="Enter your Contact Number" name="contact" value={contact} onChange={e => onChange(e)} required />
            <input type="password" placeholder="Enter your Password" name="password" value={password} onChange={e => onChange(e)} required />
            <input type="password" placeholder="Confirm your Password" name="password2" value={password2} onChange={e => onChange(e)} required />
            {!loading ? <input type="submit" className="btn" value="Sign Up" /> : <Loading />}
          </form>
        </div>

      </div>
      <div className="authRight h-100 w-50">
        <Alert />
      </div>
    </div>
  )
}

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  isAuthenticated: state.user.isAuthenticated,
  loading: state.user.loading
})

export default connect(mapStateToProps, { setAlert, register, setLoading })(Register);
