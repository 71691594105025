import { GET_CONTACTS, CONTACTS_ERROR, CREATE_CONTACT } from '../types';

const initialState = {
  contacts: [],
  loading: true
}

const contactsReducer = (state = initialState, action) => {


  const { type, payload } = action;

  switch (type) {
    case GET_CONTACTS:
      return {
        contacts: payload,
        loading: false
      }
    case CREATE_CONTACT:
      return {
        contacts: [...state.contacts, payload],
        loading: false
      }
    case CONTACTS_ERROR:
    default:
      return state;
  }

}

export default contactsReducer;