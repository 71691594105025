import React, { useState, useEffect } from 'react'
import './HeroLeftTitle.css';

const HeroLeftTitle = () => {

  return (
    <div className="cta-title">
      <h3>Organised Communication</h3>
    </div>
  )
}

export default HeroLeftTitle
