import React from 'react';
import { Link } from 'react-router-dom'
import './NavbarRight.css';
import Button from '../Button/Button';

const NavbarRight = () => {
  return (
    <div className="navbarRight w-100 h-100 d-flex flex-row">
      <ul>
        <Link to="/"><li>CONTACT US</li></Link>
        <Link to="/login"><li>LOGIN</li></Link>
      </ul>
      <Link to="/register"><Button color="#5050BF" type="btn">
        TRY IT FREE
        </Button></Link>
    </div>
  )
}

export default NavbarRight
