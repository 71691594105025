import React, { Fragment, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './App.css';
import { Provider } from 'react-redux'
import store from './Redux/store';
import LandingPage from './Routes/LandingPage'
import Login from './Routes/Login/Login';
import Register from './Routes/Register/Register';
import { loadUser } from './Redux/user/user.actions';
import setAuthToken from './Utils/setAuthToken';
import Dashboard from './Routes/Dashboard/Dashboard';


if (localStorage.token) {
  setAuthToken(localStorage.token)
}

function App() {

  useEffect(() => {
    store.dispatch(loadUser());
  }, [])

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/dashboard" component={Dashboard} />
        </Switch>
      </Router>
    </Provider>
  )
}


export default App;
